import React from 'react';
import { Link } from 'react-router-dom';

import './offerbox.scss';

export default ({title, children, link, className}) => {
    return (
        <div className={className}>
            <div className="offerbox">
                <h2>{title}</h2>
                {children}
                <Link to={`/${link}`}>więcej</Link>
            </div>
        </div>
    );
}
