import React from 'react';
import Slider from '../modules/slider/slider';
import Offer from '../modules/offer/offer';
import Post from '../modules/post/post';
import Partners from '../modules/partners/partners';
import ContactForm from '../modules/contactForm/contactForm';

const HomePage = () => {
    return (
    <div className="home-page">
        <Slider className="hide-for-small" videoPath="assets/video/bojownik.mp4">
            <span>#</span>let's create something together
        </Slider>
        <Slider className="hide-for-medium-up" imgPath="assets/img/slider.jpg">
            <span>#</span>let's create something together
        </Slider>
        <Offer />
        <Post
            title={["wspólnie","stworzymy coś dobrego" ]}
            img="assets/img/baloon.png"
            className="white"
        >
            <p>Dla nas każdy projekt to nowe, zachwycające zjawisko.
                Oglądamy go z każdej strony, po to by wyposażyć go w
                najlepsze dostępne narzędzia. Jak układankę z klocków
                budujemy plan jego wsparcia i rozwoju, a żaden z
                wykorzystanych elementów nie jest tu przypadkowy.
                Wszystkie fragmenty muszą współgrać ze sobą, po to
                by Twój projekt odniósł spektakularny sukces.</p>
        </Post>
        <Post
            title={["otoczeni specjalistami", "sięgnijmy po więcej"]}
            img="assets/img/biostat-logo.png"
            imgLink="https://www.biostat.com.pl/"
            targetBlank={true}
            className="red"
        >
            <p>Współpracujemy z najlepszymi specjalistami – możesz
                liczyć nie tylko na naszą wiedzę i doświadczenie,
                ale również na naszą innowacyjność i pomysłowość.
                Działamy w oparciu o szeroką siatkę dostawców i partnerów.
                Z przyjemnością chwalimy się współpracą z Centrum
                Badawczo-Rozwojowym BioStat – działającym dla wsparcia biznesu,
                interdyscyplinarnym zespołem naukowców, statystyków, analityków,
                biotechnologów i innowatorów z branży IT. Oprzyj się na nas by
                łatwiej sięgnąć po zamierzone cele.</p>
        </Post>
        <Partners logos={[ 'casio', 'sony', 'philips', 'merida', '4f', 'blaupunkt', 'bosh', 'canon', 'hp', 'jbl', 'lg' ]} />
        <Post
            title={["i bądźmy w dobrym", "kontakcie"]}
            className="black"
        >
            <ContactForm />
        </Post>
    </div>
    )
}

export default HomePage;