import React from 'react';
import Slider from '../modules/slider/slider';
import Post from '../modules/post/post';

export default () => {
    return (
        <div className="strategy">
            <Slider imgPath="assets/img/strategy-slider.jpg">
                <span>#</span> strategie marketingowe
            </Slider>
            <Post
                title={["żeby działać", "trzeba mieć plan..."]}
                img="assets/img/horse.png"
                className="white"
            >
                <p>Planowanie w biznesie to rzecz strategiczna.
                Pozwala nie tylko zoptymalizować proces produkcji,
                ale również przewidywać zachowania rynku i
                podejmować dobre decyzje w odpowiednim czasie.
                Dobrze zaprojektowana strategia marketingowa
                spaja wszystkie obszary Twojego biznesu, a
                podkręcana w kluczowych dla firmy miejscach,
                staje się  turbodoładowaniem zwielokrotniającym zyski.</p>
            </Post>
            <Post
                title={["wiemy,", "jak to ugryźć"]}
                img="assets/img/apple.png"
                className="red"
                invert={true}
            >
                <p>Przeprowadzimy gruntowną analizę Twojego projektu,
                    Twoich celów biznesowych oraz możliwości rynkowych,
                    by na tej podstawie dostarczyć szytą na miarę,
                    najlepszy z możliwych plan działania. Dopasowany
                    - wskażemy odpowiednie dla Twoich celów i możliwości
                    narzędzia. Konkretny - wskazujący krok po kroku jaki
                    i kiedy narzędzia te wykorzystać. I w końcu skuteczny
                    - opracowana przez nas strategia marketingowa ułatwi
                    realizowanie krótko i długoterminowych celów
                    pomnażających zyski.</p>
            </Post>
            <Post
                title={["wykorzystujemy najlepsze", "techniki i metody"]}
                img="assets/img/biostat-logo.png"
                className="black"
            >
                <p>Gwarantujemy wsparcie najlepszych na rynku specjalistów
                    - nie tylko rzetelną wiedzę ale również najnowsze
                    rozwiązania z branży IT, wspierające badania i analizę
                    przedsiębiorstw: badania rynku, potrzeb klientów oraz
                    strategie rozwoju biznesu. Dostarczane przez nich dane
                    są kluczowe dla zaprojektowania skutecznej strategii
                    marketingowej dla Twojej firmy.</p>
            </Post>
            <Post
                title={["nie zwlekajmy,", "zacznijmy już dziś"]}
                img="assets/img/clock.png"
                className="white"
            >
                <p>Skontaktuj się z nami - na podstawie przeprowadzonego
                    przez nas wywiadu i w oparciu o naszych specjalistów,
                    ich umiejętności i doświadczenie, wspólnie stworzymy
                    najlepszy możliwy plan wspierający rozwój Twojego biznesu.</p>
            </Post>
        </div>
    )
}