import React from 'react';
import './partners.scss';

export default ({logos}) => {

    const getLogos = () => logos.map((logoPath, index) => <img key={index} className="marquee-tag" src={`assets/img/${logoPath}-logo.png`} alt="some-logo" />);

    return (
        <div className="partners marquee">
            <div className="marquee-content">
                {getLogos()}{getLogos()}{getLogos()}
            </div>
        </div>
    );
}
