import React from 'react';
import Post from '../modules/post/post';
import ContactForm from '../modules/contactForm/contactForm';

export default () => {
    return (
        <div>
            <Post
                title={["zapraszamy", "do kontaktu"]}
                className="black contact"
            >
                <p>Pracujemy w każdy dzień roboczy<br/>
                od godziny 8:00 do 16:00</p><br/>
                

                <p>RedTag sp. z o.o.<br/>
                ul. Hoża 86 lok. 410<br/>
                00-682 Warszawa</p>

                <p>NIP: 701 097 59 65<br/>
                REGON: 385 808 821 000 00<br/>
                KRS:0000 8326 88</p>

                <p>fax: +48 22 100 11 47</p>
                <div className="contact-form-container">
                    <ContactForm />
                </div>
            </Post>
        </div>
    )
}