import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './App.scss';
import Header from './modules/header/header';
import Footer from './modules/footer/footer';
import About from './pages/about';
import Contact from './pages/contact';
import Loyalty from './pages/loyalty';
import Outsourcing from './pages/outsourcing';
import Strategy from './pages/strategy';

import HomePage from './pages/home';

function App() {

  const isPreprod = document.location.href.includes("naturalnie.media.pl");
  const baseName = isPreprod ? '/preprod/redtag/' : '/';

  return (
    <div>
      <BrowserRouter basename={baseName} >
        <Header />
        <Switch>
          <Route path="/" component={HomePage} exact/>
          <Route path="/about" component={About} exact/>
          <Route path="/contact" component={Contact} exact/>
          <Route path="/loyalty" component={Loyalty} exact/>
          <Route path="/outsourcing" component={Outsourcing} exact/>
          <Route path="/strategy" component={Strategy} exact/>
        </Switch>
        <Footer />
      </ BrowserRouter>
    </div>
  );
}

export default App;
