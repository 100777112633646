import React from 'react';

import './contactForm.scss';

export default class extends React.Component {

    state = {
        email: '',
        name: '',
        message: '',
        honey: '',
        status: null
    }

    clickHandler = async (event) => {
        event.preventDefault();
        this.setState({
            status: 'loading'
        })

        const { email, name, message, honey } = this.state;

        const status = await fetch('index.php', {
            headers: {
                'Content-Type': 'application/json'
              },
              method: 'POST',
              body: JSON.stringify({
                email,
                name,
                message,
                honey
            })
            
          }).then(res => res.json()).then(res => res.status);

          if(status === 'ok') {
            this.setState({
                status: 'done'
            });

            setTimeout(() => {
                this.setState({
                    status: null,
                    email: '',
                    name: '',
                    message: '',
                    honey: ''
                })
            }, 2000)
          } else if(status === 'error') {
              this.setState({
                status: 'error'
              });
          }
    }

    render () {

        const { status } = this.state;

        return (
            <div className="contact-form">
                {status === 'loading' && <div className="sending-info waiting">Trwa wysyłanie wiadomości...</div>}
                {status === 'done' && <div className="sending-info success">Wiadomość wysłana!</div>}
                <span>wypełnij formularz:</span>
                <form method="POST" action="http://localhost">
                    <input value={this.state.email} onChange={(e) => {this.setState({email: e.target.value})}} placeholder="# adres e-mail" />
                    <input value={this.state.name} onChange={(e) => {this.setState({name: e.target.value})}} placeholder="# imię i nazwisko" />
                    <textarea value={this.state.message} onChange={(e) => {this.setState({message: e.target.value})}}  placeholder="# treść wiadomości"/>
                    <span class="honey-row">
                        <input value={this.state.honey} onChange={(e) => {this.setState({honey: e.target.value})}}/>
                    </span>
                    <button onClick={this.clickHandler}>wyślij</button>
                </form>
            </div>
        )

    }

}