import React from 'react';
import './slider.scss';

export default ({videoPath, imgPath, children, className}) => {

    // Fix for autoplay in safari
    if(videoPath) {
        window.setTimeout(() => {
            document.getElementById('wideo').play()
        }, 1000);
    }

    return (
        <div className={`slider ${className}`}>
            {
                videoPath &&
                <video id="wideo" autoplay="false" loop="true" muted="true">
                    <source type="video/mp4" src={videoPath}/>
                </video>
            }
            {
                imgPath &&
                <img src={imgPath} alt="slider"/>
            }
            <div className="title">
                {children}
            </div>
            
        </div>
    );
}
