import React from 'react';
import Slider from '../modules/slider/slider';
import Post from '../modules/post/post';

export default () => {
    return (
        <div className="about">
            <Slider imgPath="assets/img/about-slider.jpg">
                <span>#</span>o nas
            </Slider>
            <Post
                title={["dzień dobry!", "poznajmy się..."]}
                img="assets/img/hash.png"
                className="white"
                >
                <p>Cieszymy się,  że tu jesteś - witamy w RedTag.
                    Poznaj nas wszystkich lepiej, palący się do
                    pracy zespół, który nie może się już doczekać,
                    by zacząć pracować nad Twoim projektem. Posiadamy
                    niezbędną wiedzę i doświadczenie, bogaty portfel
                    dostawców zajmujących się procesami produkcyjnymi
                    oraz doskonałe pomysły, realizowane przez naszych
                    specjalistów. Nie boimy się wyzwań - im trudniejsze
                    zadanie, tym większa satysfakcja z jego wykonania.
                    I zawsze kończymy to, co zaczęliśmy. To zaczynajmy!</p>
            </Post>
            <Post
                title={["...i możemy", "zaczynać!"]}
                img="assets/img/coffe.png"
                className="black"
                invert={true}
            >
                <p>Skontaktuj się z nami - napisz lub zadzwoń.
                    Wykorzystaj szeroką siatkę naszych kontaktów
                    do tego, by wesprzeć lub rozwinąć swój biznes.
                    Skorzystaj z naszej wiedzy i doświadczenia,
                    uruchom drzemiący w nas potencjał. Czym
                    prędzej zacznijmy pracować na Twój sukces.</p>
            </Post>
        </div>
    )
}