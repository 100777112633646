import React from 'react';
import OfferBox from '../offerbox/offerbox';

import './offer.scss';

export default () => {
    return (
        <div className="offer">
            <div className="container">
                <div className="row">
                    <OfferBox
                        className="col-12 col-sm-6 col-lg-4"
                        title="Outsourcing produkcyjny"
                        link="outsourcing">
                        <p>Outsorcing produkcyjny zapewni
                            lepszy start każdej firmie.
                            Do celu biegnie się łatwiej
                            bez zbędnego obciążenia.</p>
                    </OfferBox>
                    <OfferBox
                        className="col-12 col-sm-6 col-lg-4"
                        title="Strategie marketingowe"
                        link="strategy"
                    >
                        <p>Dobra strategia marketingowa
                            jest jak mapa skarbów. Jasno
                            rozpisane wskazówki pozwolą
                            łatwo dotrzeć do celu.</p>
                    </OfferBox>
                    <OfferBox
                        className="col-12 col-sm-6 col-lg-4"
                        title="Programy lojalnościowe"
                        link="loyalty"
                    >
                        <p>Uszyty na miarę program lojalnościowy
                            wzmocni więź z Klientem. Przekuje
                            zaufanie w obustronne zyski.</p>
                    </OfferBox>
                    {/* <OfferBox
                        className="col-12 col-sm-6 col-lg-3"
                        title="Aplikacje mobilne"
                        link="mobile"
                    >
                        <p>A co tu będzie?</p>
                    </OfferBox> */}
                </div>
            </div>
        </div>
    );
}
