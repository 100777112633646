import React from 'react';
import './post.scss';

export default ({title, className, children, img, imgLink, targetBlank, invert}) => {

    const prepareImg = () => {
        return imgLink
            ? <a href={imgLink} target={targetBlank ? "_blank" : ''}><img src={img} alt="post" /></a>
            : <img src={img} alt="post" />
    }
    
    return (
        <div className={`post ${className}`}>
            <div className="container">
                <div className="row">
                    {invert && 
                        <div className="col-12 col-lg-5">
                            {img && prepareImg()}
                        </div>
                    }
                    <div className={`col-12 col-lg-7 ${invert ? 'text-right' : ''}`}>
                        <div className="titles">
                            <h1 className={`first ${invert ? 'text-right' : ''}`}>{title[0]}</h1>
                            <h1 className={`second ${invert ? 'text-right' : ''}`}>{title[1]}</h1>
                        </div>
                        {children}
                    </div>
                    {!invert && 
                        <div className="col-12 col-lg-5">
                            {img && prepareImg()}
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}
