import React from 'react';

import Slider from '../modules/slider/slider';
import Post from '../modules/post/post';

export default () => {
    return (
        <div className="outsourcing">
            <Slider imgPath="assets/img/outsourcing-slider.jpg">
                <span>#</span>outsourcing produkcyjny
            </Slider>
            <Post
                title={["wspieranie", "przez obciążanie"]}
                img="assets/img/circle.png"
                className="white"
            >
                <p>Tradycyjnie realizowane procesy produkcyjne wiążą
                    się ze sporym kosztem. To przecież nie tylko samo
                    wytwarzanie, ale również związane z procesem elementy:
                    pracownicy, materiał, maszyny i w końcu koszt samego
                    procesu - wszystko to razem może być zbyt dużym balastem
                    dla firmy. Mamy na to radę: zleć produkcję naszym dostawcom.
                    Oferujemy pewny kontakt ze sprawdzonymi dostawcami
                    materiałów poligraficznych, spersonalizowanych gadżetów
                    i materiałów promocyjnych. </p>
            </Post>
            <Post
                title={["znamy", "pewnych specjalistów"]}
                img="assets/img/phone.png"
                className="red"
                invert={true}
            >
                <p>Dzięki szerokiej siatce naszych kontaktów łatwo i
                    szybko otrzymasz niezbędną pomoc doświadczonej
                    grupy projektowej, a realizację zlecimy jednemu
                    z wielu partnerów  specjalizujących się w produkcji,
                    którzy dzięki swym umiejętnościom i zgromadzonej
                    wiedzy wytwarzają produkty o najwyższej jakości.
                    Wychodząc naprzeciw oczekiwaniom odbiorców zapewniamy
                    możliwość sprawdzenia prototypu przed rozpoczęciem
                    procesu produkcji, by dać gwarancję, że zamówione
                    materiały zostaną wykonane zgodnie z oczekiwaniami
                    klienta. Każdy z wysyłanych do odbiorcy produktów,
                    możemy umieścić w specjalnie zaprojektowanym,
                    autorskim opakowaniu.</p>
            </Post>
            <Post
                title={["zostaw to", "nam i się zrelaksuj"]}
                img="assets/img/chair.png"
                className="black"
            >
                <p>Dzięki nam oszczędzasz czas, energię oraz środki,
                    które możesz spożytkować w innym obszarze swej
                    działalności. Zbędne obciążenie zostaw nam
                    - znajdziemy odpowiedniego dostawcę za Ciebie,
                    dopilnujemy terminowości procesu produkcji i
                    dostarczymy Ci produkt w konkurencyjnej cenie</p>
            </Post>
        </div>
    )
}