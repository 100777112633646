import React from 'react';
import Slider from '../modules/slider/slider';
import Post from '../modules/post/post';

export default () => {
    return (
        <div className="loyalty">
            <Slider imgPath="assets/img/loyalty-slider.jpg">
                <span>#</span> programy lojalnościowe
            </Slider>
            <Post
                title={["lojalność", "i satysfakcja"]}
                img="assets/img/card.png"
                className="white"
            >
                <p>Jedną z najwyraźniejszych cech współczesnego
                    rynku, jest potrzeba zbudowania głębszej
                    niż dotychczas relacji z klientem, a tym
                    samym pozyskania jego zaufania i lojalności.
                    Przywiązany do marki klient, przynosi
                    przewidywalny i stabilny zysk, warto zatem
                    zainwestować w jego przywiązanie. Analizy
                    rynku mówią jasno, pozyskiwanie nowych
                    klientów jest procesem trudnym i drogim - w
                    takiej sytuacji dobry program lojalnościowy
                    jest najlepszym rozwiązaniem. </p>
            </Post>
            <Post
                title={["wyjdź naprzeciw", "swoim klientom"]}
                img="assets/img/cap.png"
                className="red"
                invert={true}
            >
                <p>Program lojalnościowy jest najlepszym narzędziem
                    na przywiązanie i utrzymanie klienta. Badania
                    potwierdzają, że klienci chętnie korzystają z
                    tego typu rozwiązań - dzięki przynależności do
                    takiego programu otrzymują wiedzę o aktualnych
                    promocjach, rabaty oraz nagrody. W zamian,
                    odwdzięczając się marce lojalnością - działa
                    tu wprost zasada wzajemności. Marka nie tylko
                    zyskuje stałego klienta, ale również ma możliwość
                    ciągłego pogłębiania tej relacji, dzięki pozyskiwanym
                    w ten sposób informacjom na temat preferencji i
                    nawyków zakupowych klienta.</p>
            </Post>
            <Post
                title={["...i wspólnie", "przygotujmy program!"]}
                className="black"
            >
                <p>W oparciu o naszą wiedzę i doświadczenie przygotujmy
                    najlepszy dla Twoich klientów program lojalnościowy.
                    W zależności od modelu i strategii biznesu, proponujemy
                    szereg rozwiązań możliwych do implementacji w Twojej
                    firmie: spersonalizowane artykuły i akcesoria,
                    programy pozwalające na bieżąco identyfikować
                    potrzeby oraz oczekiwania klientów w postaci kart
                    lojalnościowych lub aplikacji mobilnych. Wszystko
                    to z myślą o wsparciu i rozwoju Twojego biznesu.</p>
            </Post>
        </div>
    )
}