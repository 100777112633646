import React from 'react';
import './header.scss';

import { Link } from 'react-router-dom';

export default () => {
    return (
        <header className="container">
            <div className="row">
                <div className="col-sm-12 col-md-3">
                    <Link className="logo-link" to="/">
                        <img className="logo" src="assets/img/logo.png" alt="logo"/>
                    </Link>
                </div>
                <nav className="menu col-sm-12 col-md-9">
                    <Link to="/about">o nas</Link>
                    <div className="offer-button">
                        <span className="position">oferta</span>
                        <ul className="offer-list">
                            <li><Link to="/outsourcing">outsourcing produkcyjny</Link></li>
                            <li><Link to="/strategy">strategie marketingowe</Link></li>
                            <li><Link to="/loyalty">programy lolajnościowy</Link></li>
                            {/* <li><Link to="/mobile">aplikacje mobilne</Link></li> */}
                        </ul>
                    </div>
                    <Link to="/contact">kontakt</Link>
                </nav>
            </div>
        </header>
    );
}