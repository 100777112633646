import React from 'react';
import './footer.scss';

export default () => {
    return (
        <footer>
            <div className="container">
                <div className="row">
                    <div className="dev-col col-sm-12 col-md-4">
                        <span>RedTag</span> <b>sp. z o.o.</b><br/>
                        ul. Hoża 86 lok. 410<br/>
                        00-682 Warszawa
                    </div>
                    <div className="dev-col col-sm-12 col-md-4">
                        let's create<br/>
                        something<br/>
                        <b>together</b>
                    </div>
                    <div className="dev-col col-sm-12 col-md-4">
                        biuro@redtag.com.pl <img src="assets/img/mail.png" alt="mail"/>
                    </div>
                </div>
            </div>
        </footer>
    )
}